import React from 'react';
import classnames from 'classnames';
import LinkButton from 'components/uiLibrary/LinkButton';
import Typography from 'components/uiLibrary/Typography';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';

import usePageContext from 'utils/hooks/usePageContext';
import { useDialogs } from 'utils/hooks/useDialogs';
import { useTranslation } from 'src/i18n';

import { TP } from 'constants/index';
import classes from './ClaimProfileCard.module.scss';

const ClaimProfileCard = ({ isVertical = false, trackingData }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { permissions } = usePageContext();
  const { isLoggedIn } = permissions;
  const { setIsLoginDialog } = useDialogs();

  const onShowLoginPopup = () => {
    if (!isLoggedIn) {
      setIsLoginDialog({ isOpen: true });
    }
  };

  return (
    <div className={classnames(classes.claimCard, { [classes.claimCardVertical]: isVertical })}>
      <LinkButton
        variant="text"
        isLink
        href="/pro/artists/details"
        styles={{ root: classnames(classes.link, { [classes.linkVertical]: isVertical }) }}
        preventDefault={!isLoggedIn}
        onClick={onShowLoginPopup}
        trackingData={trackingData}
      >
        <>
          <div className={classes.claimCard__iconWrapper}>
            <SpriteIcon icon="person" className={classes.icon} size={18} />
          </div>
          <div
            className={classnames(classes.claimCard__textWrapper, {
              [classes.claimCard__textWrapperVertical]: isVertical,
            })}
          >
            <Typography weight="bold">{t(`${TP}.FN_IS_ARTIST_MANAGER`)}</Typography>
            <div className={classnames(classes.claimProfile, { [classes.claimProfile__vertical]: isVertical })}>
              {t(`${TP}.FN_CLAIM_CREATE_PROFILE`)}
            </div>
          </div>
          <div className={classes.claimCard__rightIcon}>
            <SpriteIcon icon="chevron_right" className={classes.claimCard__rightIcon} size={20} />
          </div>
        </>
      </LinkButton>
    </div>
  );
};

export default ClaimProfileCard;
